/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/_auth'

// Create Virtual Routes

const AuthLayoutLazyImport = createFileRoute('/_auth/_layout')()
const AuthSearchIndexLazyImport = createFileRoute('/_auth/search/')()
const AuthLayoutIndexLazyImport = createFileRoute('/_auth/_layout/')()
const AuthLayoutMetricsLazyImport = createFileRoute('/_auth/_layout/metrics')()
const AuthLayoutBenchmarksLazyImport = createFileRoute(
  '/_auth/_layout/benchmarks',
)()
const AuthSearchQueryIndexLazyImport = createFileRoute(
  '/_auth/search/$query/',
)()
const AuthAccountsLayoutAccountIdLazyImport = createFileRoute(
  '/_auth/accounts/_layout/$accountId',
)()
const AuthAccountsLayoutAccountIdIndexLazyImport = createFileRoute(
  '/_auth/accounts/_layout/$accountId/',
)()
const AuthAccountsLayoutAccountIdPeopleLazyImport = createFileRoute(
  '/_auth/accounts/_layout/$accountId/people',
)()
const AuthAccountsLayoutAccountIdNotificationsLazyImport = createFileRoute(
  '/_auth/accounts/_layout/$accountId/notifications',
)()
const AuthAccountsLayoutAccountIdFinancialsLazyImport = createFileRoute(
  '/_auth/accounts/_layout/$accountId/financials',
)()
const AuthAccountsLayoutAccountIdDetailsLazyImport = createFileRoute(
  '/_auth/accounts/_layout/$accountId/details',
)()
const AuthAccountsLayoutAccountIdBenchmarksLazyImport = createFileRoute(
  '/_auth/accounts/_layout/$accountId/benchmarks',
)()
const AuthAccountsLayoutAccountIdApplicationsLazyImport = createFileRoute(
  '/_auth/accounts/_layout/$accountId/applications',
)()

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthLayoutLazyRoute = AuthLayoutLazyImport.update({
  id: '/_layout',
  getParentRoute: () => AuthRoute,
} as any).lazy(() => import('./routes/_auth/_layout.lazy').then((d) => d.Route))

const AuthSearchIndexLazyRoute = AuthSearchIndexLazyImport.update({
  id: '/search/',
  path: '/search/',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/search/index.lazy').then((d) => d.Route),
)

const AuthLayoutIndexLazyRoute = AuthLayoutIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthLayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth/_layout.index.lazy').then((d) => d.Route),
)

const AuthLayoutMetricsLazyRoute = AuthLayoutMetricsLazyImport.update({
  id: '/metrics',
  path: '/metrics',
  getParentRoute: () => AuthLayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth/_layout.metrics.lazy').then((d) => d.Route),
)

const AuthLayoutBenchmarksLazyRoute = AuthLayoutBenchmarksLazyImport.update({
  id: '/benchmarks',
  path: '/benchmarks',
  getParentRoute: () => AuthLayoutLazyRoute,
} as any).lazy(() =>
  import('./routes/_auth/_layout.benchmarks.lazy').then((d) => d.Route),
)

const AuthSearchQueryIndexLazyRoute = AuthSearchQueryIndexLazyImport.update({
  id: '/search/$query/',
  path: '/search/$query/',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/search/$query.index.lazy').then((d) => d.Route),
)

const AuthAccountsLayoutAccountIdLazyRoute =
  AuthAccountsLayoutAccountIdLazyImport.update({
    id: '/accounts/_layout/$accountId',
    path: '/accounts/$accountId',
    getParentRoute: () => AuthRoute,
  } as any).lazy(() =>
    import('./routes/_auth/accounts/_layout.$accountId.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAccountsLayoutAccountIdIndexLazyRoute =
  AuthAccountsLayoutAccountIdIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthAccountsLayoutAccountIdLazyRoute,
  } as any).lazy(() =>
    import('./routes/_auth/accounts/_layout.$accountId.index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAccountsLayoutAccountIdPeopleLazyRoute =
  AuthAccountsLayoutAccountIdPeopleLazyImport.update({
    id: '/people',
    path: '/people',
    getParentRoute: () => AuthAccountsLayoutAccountIdLazyRoute,
  } as any).lazy(() =>
    import('./routes/_auth/accounts/_layout.$accountId.people.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAccountsLayoutAccountIdNotificationsLazyRoute =
  AuthAccountsLayoutAccountIdNotificationsLazyImport.update({
    id: '/notifications',
    path: '/notifications',
    getParentRoute: () => AuthAccountsLayoutAccountIdLazyRoute,
  } as any).lazy(() =>
    import(
      './routes/_auth/accounts/_layout.$accountId.notifications.lazy'
    ).then((d) => d.Route),
  )

const AuthAccountsLayoutAccountIdFinancialsLazyRoute =
  AuthAccountsLayoutAccountIdFinancialsLazyImport.update({
    id: '/financials',
    path: '/financials',
    getParentRoute: () => AuthAccountsLayoutAccountIdLazyRoute,
  } as any).lazy(() =>
    import('./routes/_auth/accounts/_layout.$accountId.financials.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAccountsLayoutAccountIdDetailsLazyRoute =
  AuthAccountsLayoutAccountIdDetailsLazyImport.update({
    id: '/details',
    path: '/details',
    getParentRoute: () => AuthAccountsLayoutAccountIdLazyRoute,
  } as any).lazy(() =>
    import('./routes/_auth/accounts/_layout.$accountId.details.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAccountsLayoutAccountIdBenchmarksLazyRoute =
  AuthAccountsLayoutAccountIdBenchmarksLazyImport.update({
    id: '/benchmarks',
    path: '/benchmarks',
    getParentRoute: () => AuthAccountsLayoutAccountIdLazyRoute,
  } as any).lazy(() =>
    import('./routes/_auth/accounts/_layout.$accountId.benchmarks.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthAccountsLayoutAccountIdApplicationsLazyRoute =
  AuthAccountsLayoutAccountIdApplicationsLazyImport.update({
    id: '/applications',
    path: '/applications',
    getParentRoute: () => AuthAccountsLayoutAccountIdLazyRoute,
  } as any).lazy(() =>
    import('./routes/_auth/accounts/_layout.$accountId.applications.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_layout': {
      id: '/_auth/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthLayoutLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_layout/benchmarks': {
      id: '/_auth/_layout/benchmarks'
      path: '/benchmarks'
      fullPath: '/benchmarks'
      preLoaderRoute: typeof AuthLayoutBenchmarksLazyImport
      parentRoute: typeof AuthLayoutLazyImport
    }
    '/_auth/_layout/metrics': {
      id: '/_auth/_layout/metrics'
      path: '/metrics'
      fullPath: '/metrics'
      preLoaderRoute: typeof AuthLayoutMetricsLazyImport
      parentRoute: typeof AuthLayoutLazyImport
    }
    '/_auth/_layout/': {
      id: '/_auth/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthLayoutIndexLazyImport
      parentRoute: typeof AuthLayoutLazyImport
    }
    '/_auth/search/': {
      id: '/_auth/search/'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof AuthSearchIndexLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/accounts/_layout/$accountId': {
      id: '/_auth/accounts/_layout/$accountId'
      path: '/accounts/$accountId'
      fullPath: '/accounts/$accountId'
      preLoaderRoute: typeof AuthAccountsLayoutAccountIdLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/search/$query/': {
      id: '/_auth/search/$query/'
      path: '/search/$query'
      fullPath: '/search/$query'
      preLoaderRoute: typeof AuthSearchQueryIndexLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/accounts/_layout/$accountId/applications': {
      id: '/_auth/accounts/_layout/$accountId/applications'
      path: '/applications'
      fullPath: '/accounts/$accountId/applications'
      preLoaderRoute: typeof AuthAccountsLayoutAccountIdApplicationsLazyImport
      parentRoute: typeof AuthAccountsLayoutAccountIdLazyImport
    }
    '/_auth/accounts/_layout/$accountId/benchmarks': {
      id: '/_auth/accounts/_layout/$accountId/benchmarks'
      path: '/benchmarks'
      fullPath: '/accounts/$accountId/benchmarks'
      preLoaderRoute: typeof AuthAccountsLayoutAccountIdBenchmarksLazyImport
      parentRoute: typeof AuthAccountsLayoutAccountIdLazyImport
    }
    '/_auth/accounts/_layout/$accountId/details': {
      id: '/_auth/accounts/_layout/$accountId/details'
      path: '/details'
      fullPath: '/accounts/$accountId/details'
      preLoaderRoute: typeof AuthAccountsLayoutAccountIdDetailsLazyImport
      parentRoute: typeof AuthAccountsLayoutAccountIdLazyImport
    }
    '/_auth/accounts/_layout/$accountId/financials': {
      id: '/_auth/accounts/_layout/$accountId/financials'
      path: '/financials'
      fullPath: '/accounts/$accountId/financials'
      preLoaderRoute: typeof AuthAccountsLayoutAccountIdFinancialsLazyImport
      parentRoute: typeof AuthAccountsLayoutAccountIdLazyImport
    }
    '/_auth/accounts/_layout/$accountId/notifications': {
      id: '/_auth/accounts/_layout/$accountId/notifications'
      path: '/notifications'
      fullPath: '/accounts/$accountId/notifications'
      preLoaderRoute: typeof AuthAccountsLayoutAccountIdNotificationsLazyImport
      parentRoute: typeof AuthAccountsLayoutAccountIdLazyImport
    }
    '/_auth/accounts/_layout/$accountId/people': {
      id: '/_auth/accounts/_layout/$accountId/people'
      path: '/people'
      fullPath: '/accounts/$accountId/people'
      preLoaderRoute: typeof AuthAccountsLayoutAccountIdPeopleLazyImport
      parentRoute: typeof AuthAccountsLayoutAccountIdLazyImport
    }
    '/_auth/accounts/_layout/$accountId/': {
      id: '/_auth/accounts/_layout/$accountId/'
      path: '/'
      fullPath: '/accounts/$accountId/'
      preLoaderRoute: typeof AuthAccountsLayoutAccountIdIndexLazyImport
      parentRoute: typeof AuthAccountsLayoutAccountIdLazyImport
    }
  }
}

// Create and export the route tree

interface AuthLayoutLazyRouteChildren {
  AuthLayoutBenchmarksLazyRoute: typeof AuthLayoutBenchmarksLazyRoute
  AuthLayoutMetricsLazyRoute: typeof AuthLayoutMetricsLazyRoute
  AuthLayoutIndexLazyRoute: typeof AuthLayoutIndexLazyRoute
}

const AuthLayoutLazyRouteChildren: AuthLayoutLazyRouteChildren = {
  AuthLayoutBenchmarksLazyRoute: AuthLayoutBenchmarksLazyRoute,
  AuthLayoutMetricsLazyRoute: AuthLayoutMetricsLazyRoute,
  AuthLayoutIndexLazyRoute: AuthLayoutIndexLazyRoute,
}

const AuthLayoutLazyRouteWithChildren = AuthLayoutLazyRoute._addFileChildren(
  AuthLayoutLazyRouteChildren,
)

interface AuthAccountsLayoutAccountIdLazyRouteChildren {
  AuthAccountsLayoutAccountIdApplicationsLazyRoute: typeof AuthAccountsLayoutAccountIdApplicationsLazyRoute
  AuthAccountsLayoutAccountIdBenchmarksLazyRoute: typeof AuthAccountsLayoutAccountIdBenchmarksLazyRoute
  AuthAccountsLayoutAccountIdDetailsLazyRoute: typeof AuthAccountsLayoutAccountIdDetailsLazyRoute
  AuthAccountsLayoutAccountIdFinancialsLazyRoute: typeof AuthAccountsLayoutAccountIdFinancialsLazyRoute
  AuthAccountsLayoutAccountIdNotificationsLazyRoute: typeof AuthAccountsLayoutAccountIdNotificationsLazyRoute
  AuthAccountsLayoutAccountIdPeopleLazyRoute: typeof AuthAccountsLayoutAccountIdPeopleLazyRoute
  AuthAccountsLayoutAccountIdIndexLazyRoute: typeof AuthAccountsLayoutAccountIdIndexLazyRoute
}

const AuthAccountsLayoutAccountIdLazyRouteChildren: AuthAccountsLayoutAccountIdLazyRouteChildren =
  {
    AuthAccountsLayoutAccountIdApplicationsLazyRoute:
      AuthAccountsLayoutAccountIdApplicationsLazyRoute,
    AuthAccountsLayoutAccountIdBenchmarksLazyRoute:
      AuthAccountsLayoutAccountIdBenchmarksLazyRoute,
    AuthAccountsLayoutAccountIdDetailsLazyRoute:
      AuthAccountsLayoutAccountIdDetailsLazyRoute,
    AuthAccountsLayoutAccountIdFinancialsLazyRoute:
      AuthAccountsLayoutAccountIdFinancialsLazyRoute,
    AuthAccountsLayoutAccountIdNotificationsLazyRoute:
      AuthAccountsLayoutAccountIdNotificationsLazyRoute,
    AuthAccountsLayoutAccountIdPeopleLazyRoute:
      AuthAccountsLayoutAccountIdPeopleLazyRoute,
    AuthAccountsLayoutAccountIdIndexLazyRoute:
      AuthAccountsLayoutAccountIdIndexLazyRoute,
  }

const AuthAccountsLayoutAccountIdLazyRouteWithChildren =
  AuthAccountsLayoutAccountIdLazyRoute._addFileChildren(
    AuthAccountsLayoutAccountIdLazyRouteChildren,
  )

interface AuthRouteChildren {
  AuthLayoutLazyRoute: typeof AuthLayoutLazyRouteWithChildren
  AuthSearchIndexLazyRoute: typeof AuthSearchIndexLazyRoute
  AuthAccountsLayoutAccountIdLazyRoute: typeof AuthAccountsLayoutAccountIdLazyRouteWithChildren
  AuthSearchQueryIndexLazyRoute: typeof AuthSearchQueryIndexLazyRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLayoutLazyRoute: AuthLayoutLazyRouteWithChildren,
  AuthSearchIndexLazyRoute: AuthSearchIndexLazyRoute,
  AuthAccountsLayoutAccountIdLazyRoute:
    AuthAccountsLayoutAccountIdLazyRouteWithChildren,
  AuthSearchQueryIndexLazyRoute: AuthSearchQueryIndexLazyRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthLayoutLazyRouteWithChildren
  '/benchmarks': typeof AuthLayoutBenchmarksLazyRoute
  '/metrics': typeof AuthLayoutMetricsLazyRoute
  '/': typeof AuthLayoutIndexLazyRoute
  '/search': typeof AuthSearchIndexLazyRoute
  '/accounts/$accountId': typeof AuthAccountsLayoutAccountIdLazyRouteWithChildren
  '/search/$query': typeof AuthSearchQueryIndexLazyRoute
  '/accounts/$accountId/applications': typeof AuthAccountsLayoutAccountIdApplicationsLazyRoute
  '/accounts/$accountId/benchmarks': typeof AuthAccountsLayoutAccountIdBenchmarksLazyRoute
  '/accounts/$accountId/details': typeof AuthAccountsLayoutAccountIdDetailsLazyRoute
  '/accounts/$accountId/financials': typeof AuthAccountsLayoutAccountIdFinancialsLazyRoute
  '/accounts/$accountId/notifications': typeof AuthAccountsLayoutAccountIdNotificationsLazyRoute
  '/accounts/$accountId/people': typeof AuthAccountsLayoutAccountIdPeopleLazyRoute
  '/accounts/$accountId/': typeof AuthAccountsLayoutAccountIdIndexLazyRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/benchmarks': typeof AuthLayoutBenchmarksLazyRoute
  '/metrics': typeof AuthLayoutMetricsLazyRoute
  '/': typeof AuthLayoutIndexLazyRoute
  '/search': typeof AuthSearchIndexLazyRoute
  '/search/$query': typeof AuthSearchQueryIndexLazyRoute
  '/accounts/$accountId/applications': typeof AuthAccountsLayoutAccountIdApplicationsLazyRoute
  '/accounts/$accountId/benchmarks': typeof AuthAccountsLayoutAccountIdBenchmarksLazyRoute
  '/accounts/$accountId/details': typeof AuthAccountsLayoutAccountIdDetailsLazyRoute
  '/accounts/$accountId/financials': typeof AuthAccountsLayoutAccountIdFinancialsLazyRoute
  '/accounts/$accountId/notifications': typeof AuthAccountsLayoutAccountIdNotificationsLazyRoute
  '/accounts/$accountId/people': typeof AuthAccountsLayoutAccountIdPeopleLazyRoute
  '/accounts/$accountId': typeof AuthAccountsLayoutAccountIdIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_auth/_layout': typeof AuthLayoutLazyRouteWithChildren
  '/_auth/_layout/benchmarks': typeof AuthLayoutBenchmarksLazyRoute
  '/_auth/_layout/metrics': typeof AuthLayoutMetricsLazyRoute
  '/_auth/_layout/': typeof AuthLayoutIndexLazyRoute
  '/_auth/search/': typeof AuthSearchIndexLazyRoute
  '/_auth/accounts/_layout/$accountId': typeof AuthAccountsLayoutAccountIdLazyRouteWithChildren
  '/_auth/search/$query/': typeof AuthSearchQueryIndexLazyRoute
  '/_auth/accounts/_layout/$accountId/applications': typeof AuthAccountsLayoutAccountIdApplicationsLazyRoute
  '/_auth/accounts/_layout/$accountId/benchmarks': typeof AuthAccountsLayoutAccountIdBenchmarksLazyRoute
  '/_auth/accounts/_layout/$accountId/details': typeof AuthAccountsLayoutAccountIdDetailsLazyRoute
  '/_auth/accounts/_layout/$accountId/financials': typeof AuthAccountsLayoutAccountIdFinancialsLazyRoute
  '/_auth/accounts/_layout/$accountId/notifications': typeof AuthAccountsLayoutAccountIdNotificationsLazyRoute
  '/_auth/accounts/_layout/$accountId/people': typeof AuthAccountsLayoutAccountIdPeopleLazyRoute
  '/_auth/accounts/_layout/$accountId/': typeof AuthAccountsLayoutAccountIdIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/benchmarks'
    | '/metrics'
    | '/'
    | '/search'
    | '/accounts/$accountId'
    | '/search/$query'
    | '/accounts/$accountId/applications'
    | '/accounts/$accountId/benchmarks'
    | '/accounts/$accountId/details'
    | '/accounts/$accountId/financials'
    | '/accounts/$accountId/notifications'
    | '/accounts/$accountId/people'
    | '/accounts/$accountId/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/benchmarks'
    | '/metrics'
    | '/'
    | '/search'
    | '/search/$query'
    | '/accounts/$accountId/applications'
    | '/accounts/$accountId/benchmarks'
    | '/accounts/$accountId/details'
    | '/accounts/$accountId/financials'
    | '/accounts/$accountId/notifications'
    | '/accounts/$accountId/people'
    | '/accounts/$accountId'
  id:
    | '__root__'
    | '/_auth'
    | '/_auth/_layout'
    | '/_auth/_layout/benchmarks'
    | '/_auth/_layout/metrics'
    | '/_auth/_layout/'
    | '/_auth/search/'
    | '/_auth/accounts/_layout/$accountId'
    | '/_auth/search/$query/'
    | '/_auth/accounts/_layout/$accountId/applications'
    | '/_auth/accounts/_layout/$accountId/benchmarks'
    | '/_auth/accounts/_layout/$accountId/details'
    | '/_auth/accounts/_layout/$accountId/financials'
    | '/_auth/accounts/_layout/$accountId/notifications'
    | '/_auth/accounts/_layout/$accountId/people'
    | '/_auth/accounts/_layout/$accountId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/_layout",
        "/_auth/search/",
        "/_auth/accounts/_layout/$accountId",
        "/_auth/search/$query/"
      ]
    },
    "/_auth/_layout": {
      "filePath": "_auth/_layout.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_layout/benchmarks",
        "/_auth/_layout/metrics",
        "/_auth/_layout/"
      ]
    },
    "/_auth/_layout/benchmarks": {
      "filePath": "_auth/_layout.benchmarks.lazy.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/metrics": {
      "filePath": "_auth/_layout.metrics.lazy.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/": {
      "filePath": "_auth/_layout.index.lazy.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/search/": {
      "filePath": "_auth/search/index.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/accounts/_layout/$accountId": {
      "filePath": "_auth/accounts/_layout.$accountId.lazy.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/accounts/_layout/$accountId/applications",
        "/_auth/accounts/_layout/$accountId/benchmarks",
        "/_auth/accounts/_layout/$accountId/details",
        "/_auth/accounts/_layout/$accountId/financials",
        "/_auth/accounts/_layout/$accountId/notifications",
        "/_auth/accounts/_layout/$accountId/people",
        "/_auth/accounts/_layout/$accountId/"
      ]
    },
    "/_auth/search/$query/": {
      "filePath": "_auth/search/$query.index.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/accounts/_layout/$accountId/applications": {
      "filePath": "_auth/accounts/_layout.$accountId.applications.lazy.tsx",
      "parent": "/_auth/accounts/_layout/$accountId"
    },
    "/_auth/accounts/_layout/$accountId/benchmarks": {
      "filePath": "_auth/accounts/_layout.$accountId.benchmarks.lazy.tsx",
      "parent": "/_auth/accounts/_layout/$accountId"
    },
    "/_auth/accounts/_layout/$accountId/details": {
      "filePath": "_auth/accounts/_layout.$accountId.details.lazy.tsx",
      "parent": "/_auth/accounts/_layout/$accountId"
    },
    "/_auth/accounts/_layout/$accountId/financials": {
      "filePath": "_auth/accounts/_layout.$accountId.financials.lazy.tsx",
      "parent": "/_auth/accounts/_layout/$accountId"
    },
    "/_auth/accounts/_layout/$accountId/notifications": {
      "filePath": "_auth/accounts/_layout.$accountId.notifications.lazy.tsx",
      "parent": "/_auth/accounts/_layout/$accountId"
    },
    "/_auth/accounts/_layout/$accountId/people": {
      "filePath": "_auth/accounts/_layout.$accountId.people.lazy.tsx",
      "parent": "/_auth/accounts/_layout/$accountId"
    },
    "/_auth/accounts/_layout/$accountId/": {
      "filePath": "_auth/accounts/_layout.$accountId.index.lazy.tsx",
      "parent": "/_auth/accounts/_layout/$accountId"
    }
  }
}
ROUTE_MANIFEST_END */
