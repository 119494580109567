import nodePath from 'path-browserify';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LANGUAGES from './utils/language';
import moment from 'moment';
import {
  path, intersection, set, lensPath,
} from 'rambdax';

const resources: any = LANGUAGES.reduce((accum, langObj) => ({
  ...accum,
  [langObj.value]: {},
}), {});

const parseObjectDeep = (lang: string, obj: { [key: string]: any }): object => {
  if (Object.keys(obj).includes(lang)) {
    return obj[lang];
  }
  return Object.keys(obj).reduce((accum, key) => {
    if (!(obj[key] instanceof Object && !Array.isArray(obj[key]) && obj[key] !== null)) {
      return accum;
    }
    return {
      ...accum,
      [key]: parseObjectDeep(lang, obj[key]),
    };
  }, {});
};

if (process.env.NODE_ENV !== 'test') {
  const req = require.context('./', true, /\.lang\.json$/);
  req.keys()
    .sort()
    .forEach((fileName) => {
      const directory = nodePath.dirname(fileName);
      const baseFileNameParts = nodePath.basename(fileName, '.json').split('.');
      let objPath;

      if (directory === '.') {
        objPath = baseFileNameParts[0];
      } else {
        objPath = directory.replace(new RegExp(`^\\.${nodePath.sep}`), '')
          .split(nodePath.sep)
          .join('.');
      }
      const allLangRes = req(fileName);
      LANGUAGES.forEach((langObj) => {
        const existingValue = path(objPath, resources[langObj.value]);
        let res = parseObjectDeep(langObj.value, allLangRes);
        if (existingValue) {
          const dupTranslationKeys = intersection(Object.keys(res), Object.keys(existingValue));
          if (dupTranslationKeys.length) {
            throw Error('Duplicate translation keys: ' + dupTranslationKeys.join(', '));
          }
          res = Object.assign({}, existingValue, res);
        }
        resources[langObj.value] = set(lensPath(objPath), res, resources[langObj.value]);
      });
    });
}

console.log(resources);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false, // react already protects against xss
      formatSeparator: ',',
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
  });

moment.locale(i18n.language);

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
