import { gql } from '@apollo/client';

export type CreateAccount = {
  createAccount: string;
}

export type CreateAccountVariables = {
  orgId: string;
  groupId?: string | null;
  companySearchId: string;
}

const CREATE_ACCOUNT = gql`
  mutation createAccount(
    $orgId: ID!
    $groupId: ID
    $companySearchId: ID!
  ) {
    createAccount(
      orgId: $orgId
      groupId: $groupId
      companySearchId: $companySearchId
    )
  }
`;

export default CREATE_ACCOUNT;
