import { gql } from '@apollo/client';

export type AccountSearchResult = {
  groupId: string;
  accountId: string;
  id: string;
  name: string;
  officeType: string;
  externalId: string;
  externalProvider: string;
  simpleAddress: string;
  status: string;
  statusDescription: string;
}

export type SearchAccountsByCompanyName = {
  searchAccountsByCompanyName: {
    accounts: AccountSearchResult[];
    totalAccounts: number;
  };
}

export type SearchAccountsByCompanyNameVariables = {
  name: string;
  orgId: string;
  groupId?: string;
  skip?: number;
  limit?: number;
}

const SEARCH_ACCOUNTS_BY_COMPANY_NAME = gql`
  query searchAccountsByCompanyName(
    $name: String!
    $orgId: ID!
    $groupId:ID
    $skip: Int
    $limit: Int
  ) {
    searchAccountsByCompanyName(
      name: $name
      orgId: $orgId
      groupId: $groupId
      skip: $skip
      limit: $limit
    ) {
      accounts {
        groupId
        accountId
        id
        name
        officeType
        externalId
        externalProvider
        simpleAddress
        status
        statusDescription
      }
      totalAccounts
    }
  }
`;

export default SEARCH_ACCOUNTS_BY_COMPANY_NAME;
