import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  Autocomplete,
  Box,
  Typography,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlusIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { identity } from 'rambdax';

import debounce from '../../utils/debounce';
import { COLORS } from '../../theme';

type SearchResult = {
  id: string;
  name: string;
  status: string;
  statusDescription: string;
  address: string;
  type: string;
  source: 'internal' | 'external' | 'internal-summary' | 'external-summary' | 'no-accounts' | 'no-companies' | 'advanced-search';
};

type Group = {
  id: string;
  name: string;
};

interface CompanySearchBarProps {
  internalSearchResults: SearchResult[] | null;
  externalSearchResults: SearchResult[] | null;
  internalTotalCount?: number;
  externalTotalCount?: number;
  isLoading?: boolean;
  onSearch?: (searchTerm: string) => void;
  onViewCompany?: (id: string) => void;
  onAddCompany?: (id: string, groupId: string | null) => void;
  onAdvancedSearchClick?: (searchTerm: string) => void;
  onCloseMenu?: () => void;
  debounceInputMs?: number;
  groups?: Group[] | null;
  minimumSearchTermLength?: number;
}

const MINIMUM_SEARCH_TERM_LENGTH = 2;

const commonStaticResults = {
  name: '',
  status: '',
  statusDescription: '',
  address: '',
  type: '',
};
// Create constant items in the search results menu
const internalSummarySearchResult: SearchResult = {
  id: 'internal-summary',
  source: 'internal-summary' as const,
  ...commonStaticResults,
};

const externalSummarySearchResult: SearchResult = {
  id: 'external-summary',
  source: 'external-summary' as const,
  ...commonStaticResults,
};

const noAccountsSearchResult: SearchResult = {
  id: 'no-accounts',
  source: 'no-accounts' as const,
  ...commonStaticResults,
};

const noCompaniesSearchResult: SearchResult = {
  id: 'no-companies',
  source: 'no-companies' as const,
  ...commonStaticResults,
};

const advancedSearchResult: SearchResult = {
  id: 'advanced-search',
  source: 'advanced-search' as const,
  ...commonStaticResults,
};

const CompanySearchBar: React.FC<CompanySearchBarProps> = ({
  onSearch = identity,
  onViewCompany = identity,
  onAddCompany = identity,
  onAdvancedSearchClick = identity,
  isLoading = false,
  internalSearchResults,
  externalSearchResults,
  internalTotalCount = 0,
  externalTotalCount = 0,
  debounceInputMs = 500,
  groups = null,
  minimumSearchTermLength = MINIMUM_SEARCH_TERM_LENGTH,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'company-search-bar' });
  const [ inputValue, setInputValue ] = useState('');
  const [ highlightedOption, setHighlightedOption ] = useState<SearchResult | null>(null);
  const [ addCompanyModalOpen, setAddCompanyModalOpen ] = useState(false);
  const [ selectedCompany, setSelectedCompany ] = useState<SearchResult | null>(null);
  const [ selectedGroupId, setSelectedGroupId ] = useState<string>('');
  const inputRef = React.useRef<HTMLInputElement>(null);

  const blurInput = () => {
    inputRef.current?.blur();
    setHighlightedOption(null);
  };

  const blurAndResetInput = () => {
    setInputValue('');
    blurInput();
  };

  const handleAddCompanyClick = (company: SearchResult) => {
    setSelectedCompany(company);
    setAddCompanyModalOpen(true);
  };

  const handleConfirmAddCompany = () => {
    if (selectedCompany) {
      onAddCompany(selectedCompany.id, selectedGroupId || null);
      setAddCompanyModalOpen(false);
      setSelectedCompany(null);
      setSelectedGroupId('');
      blurAndResetInput();
    }
  };

  const handleCancelAddCompany = () => {
    setAddCompanyModalOpen(false);
  };

  const handleSearch = useCallback(
    (searchTerm: string) => {
      onSearch(searchTerm);
    },
    [ onSearch ],
  );

  const debouncedSearch = useMemo(
    () => debounce(handleSearch, debounceInputMs),
    [ handleSearch, debounceInputMs ],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [ debouncedSearch ]);

  const handleInputChange = (_: React.SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
    debouncedSearch(newInputValue);
  };

  // Combine results for Autocomplete
  const allResults = useMemo(() => {
    const internal = (internalSearchResults?.length) ? internalSearchResults : (!internalSearchResults || isLoading || inputValue.length < minimumSearchTermLength) ? [] : [ noAccountsSearchResult ];
    const external = (externalSearchResults?.length) ? externalSearchResults : (!externalSearchResults || isLoading || inputValue.length < minimumSearchTermLength) ? [] : [ noCompaniesSearchResult ];
    return (!internalSearchResults || !externalSearchResults || isLoading || inputValue.length < minimumSearchTermLength)
      ? []
      : [
          ...internal,
          internalSummarySearchResult,
          ...external,
          externalSummarySearchResult,
          advancedSearchResult,
        ];
  }, [
    internalSearchResults,
    externalSearchResults,
    isLoading,
    inputValue,
    minimumSearchTermLength,
  ]);

  // Handle advanced search click
  const handleAdvancedSearchClick = () => {
    onAdvancedSearchClick(inputValue);
    blurAndResetInput();
  };

  return (
    <>
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        freeSolo
        fullWidth
        // Disable built-in filtering since we handle filtering through the API
        filterOptions={(x) => x}
        // Prevent menu from closing when an option is clicked
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          } else {
            return option.name;
          }
        }}
        id='search-autocomplete'
        inputValue={inputValue}
        loading={isLoading}
        loadingText={t('searching')}
        options={allResults}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            label={t('search_companies')}
            slotProps={{
              input: {
                ...params.InputProps,
                size: 'small',
                // Handle Enter key press to trigger view/add action on highlighted option
                onKeyDown: (e) => {
                  if (e.key === 'Enter' && highlightedOption) {
                    e.preventDefault();
                    e.stopPropagation();
                    blurInput();
                    if (highlightedOption.source === 'internal') {
                      onViewCompany(highlightedOption.id);
                    } else if (highlightedOption.source === 'external') {
                      handleAddCompanyClick(highlightedOption);
                    } else {
                      setInputValue('');
                      onAdvancedSearchClick('');
                    }
                  }
                },
                sx: {
                  borderRadius: 30,
                  // Push the search input text to the right to align with the flat part of the input border
                  '& .MuiAutocomplete-input.MuiInputBase-input.MuiInputBase-inputSizeSmall': {
                    pl: 2,
                  },
                  // Hide the mini label background
                  '& legend>span': {
                    display: 'none',
                  },
                },
                endAdornment: (
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 1,
                    gap: 1,
                  }}
                  >
                    {(inputValue.length > 0) && (
                      <IconButton
                        aria-label='clear'
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          setInputValue('');
                        }}
                      >
                        <ClearIcon fontSize='inherit' />
                      </IconButton>
                    )}
                    {isLoading
                      ? (
                        <CircularProgress size={20} />
                        )
                      : (
                        <SearchIcon />
                        )}
                  </Box>
                ),
              },
            }}
            sx={{
              // Position the placeholder label correctly with the smaller search input
              '& .MuiFormLabel-root': {
                transform: 'translate(18px, 8px) scale(1)',
              },
              // Hide the mini label when the input is focused or filled
              '& .MuiFormLabel-root.Mui-focused': {
                display: 'none',
              },
              '& .MuiFormLabel-root.MuiFormLabel-filled': {
                display: 'none',
              },
            }}
            variant='outlined'
          />
        )}
        renderOption={({ key, ...props }, option, state) => {
          if (option.source === 'no-accounts' || option.source === 'no-companies') {
            return (
              <Box
                key={option.id}
                sx={{
                  py: 1,
                  px: 2,
                }}
                onClick={(e) => {
                  // Stop propagation to prevent the default selection behavior
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Typography
                  color='text.secondary'
                  sx={{
                    fontSize: {
                      xs: '0.75rem',
                      sm: '0.875rem',
                      md: '0.875rem',
                    },
                  }}
                  variant='body2'
                >
                  {t(`${option.source.replace('-', '_')}_found`)}
                </Typography>
              </Box>
            );
          }
          if (option.source === 'internal-summary' || option.source === 'external-summary') {
            return (
              <Box
                key={option.id}
                sx={{
                  my: 1,
                  backgroundColor: COLORS.lightestGray,
                }}
                onClick={(e) => {
                  // Stop propagation to prevent the default selection behavior
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Divider
                  sx={{ mb: 1 }}
                />
                <Typography
                  color='text.secondary'
                  sx={{
                    fontSize: {
                      xs: '0.75rem',
                      sm: '0.875rem',
                      md: '0.875rem',
                    },
                    px: 2,
                  }}
                  variant='body2'
                >
                  <InfoIcon sx={{
                    fontSize: '1rem',
                    mr: 0.5,
                    verticalAlign: 'middle',
                  }}
                  />
                  {t('showing_results', {
                    count: (option.source === 'internal-summary') ? internalSearchResults?.length : externalSearchResults?.length,
                    total: (option.source === 'internal-summary') ? internalTotalCount : externalTotalCount,
                    type: (option.source === 'internal-summary') ? t('existing_accounts') : t('matching_companies'),
                  })}
                </Typography>
                <Divider
                  sx={{ mt: 1 }}
                />
              </Box>
            );
          }
          if (option.source === 'advanced-search') {
            return (
              <li
                key={option.id}
                {...props}
                style={{
                  padding: '8px 16px',
                }}
                onClick={(e) => {
                  // Stop propagation to prevent the default selection behavior
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Typography
                  color='text.secondary'
                  sx={{
                    fontSize: {
                      xs: '0.75rem',
                      sm: '0.875rem',
                      md: '0.875rem',
                    },
                    pr: 2,
                  }}
                  variant='body2'
                >
                  {t('cant_find_company')}
                </Typography>
                <Button
                  size='small'
                  sx={{
                    fontSize: {
                      xs: '0.75rem',
                      sm: '0.875rem',
                      md: '0.875rem',
                    },
                    textTransform: 'none',
                    display: 'inline',
                  }}
                  variant='text'
                  onClick={handleAdvancedSearchClick}
                >
                  {t('advanced_search')}
                </Button>
              </li>
            );
          }
          return (
            <li
              {...props}
              key={option.id}
              style={{
                padding: 8,
                cursor: 'default',
              }}
              onClick={(e) => {
                // Stop propagation to prevent the default selection behavior
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Box sx={{
                width: '100%',
                pl: 1,
                // Color-coded border to indicate company status
                borderLeft: '4px solid',
                borderLeftColor: option.status.toLowerCase() === 'active' ? COLORS.lightGreen : COLORS.lightYellow,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
              >
                {/* Company details section */}
                <Box sx={{ flexGrow: 1 }}>
                  {/* Company name with responsive font size */}
                  <Typography
                    fontWeight='medium'
                    sx={{
                      whiteSpace: 'normal',
                      lineHeight: 1.2,
                      fontSize: {
                        xs: '0.875rem',
                        sm: '1rem',
                        md: '1.15rem',
                      },
                    }}
                    variant='subtitle1'
                  >
                    {option.name}
                  </Typography>
                  {/* Company address with responsive font size */}
                  <Typography
                    color='text.secondary'
                    sx={{
                      whiteSpace: 'normal',
                      lineHeight: 1.2,
                      fontSize: {
                        xs: '0.75rem',
                        sm: '0.8rem',
                        md: '0.875rem',
                      },
                    }}
                    variant='body2'
                  >
                    {option.address}
                  </Typography>
                  {/* Status and type chips */}
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    my: 0.5,
                  }}
                  >
                    {/* Status chip with color based on company status */}
                    <Chip
                      label={option.status.toLowerCase() === 'active' ? 'Active' : option.statusDescription}
                      size='small'
                      sx={{
                        flexShrink: 1,
                        fontSize: {
                          xs: '0.65rem',
                          sm: '0.7rem',
                          md: '0.75rem',
                        },
                        color: 'black',
                        borderColor: option.status.toLowerCase() === 'active' ? COLORS.lightGreen : COLORS.lightYellow,
                      }}
                      variant='outlined'
                    />
                    {/* Company type chip if available */}
                    {option.type && (
                      <Chip
                        color='default'
                        label={t(`type.${option.type.toLowerCase()}`)}
                        size='small'
                        sx={{
                          fontSize: {
                            xs: '0.65rem',
                            sm: '0.7rem',
                            md: '0.75rem',
                          },
                          color: 'black',
                        }}
                        variant='outlined'
                      />
                    )}
                  </Box>
                </Box>
                {/* Action button section */}
                <Box sx={{
                  width: 100,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                >
                  {/* View/Add button with responsive sizing */}
                  <Button
                    aria-label={option.source === 'internal' ? t('view_company', { name: option.name }) : t('add_company', { name: option.name })}
                    endIcon={option.source === 'internal' ? <KeyboardArrowRightIcon /> : <PlusIcon />}
                    role='button'
                    size='small'
                    sx={{
                      minWidth: {
                        xs: 60,
                        sm: 70,
                        md: 90,
                      },
                      width: {
                        xs: 60,
                        sm: 70,
                        md: 90,
                      },
                      px: {
                        xs: 1,
                        sm: 1.5,
                        md: 2,
                      },
                      '& .MuiButton-endIcon': {
                        ml: {
                          xs: 0.5,
                          sm: 0.75,
                          md: 1,
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: {
                            xs: '1rem',
                            sm: '1.25rem',
                            md: '1.5rem',
                          },
                        },
                      },
                      fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem',
                        md: '0.875rem',
                      },
                    }}
                    tabIndex={0}
                    variant={option.source === 'internal' ? 'outlined' : 'contained'}
                    onClick={(e) => {
                      e.stopPropagation();
                      blurInput();
                      if (option.source === 'internal') {
                        onViewCompany(option.id);
                      } else {
                        handleAddCompanyClick(option);
                      }
                    }}
                  >
                    {option.source === 'internal' ? t('view') : t('add')}
                  </Button>
                </Box>
              </Box>
            </li>
          );
        }}
        slotProps={{
          // Shrink the width of and position the dropdown menu so that it's directly under the flat part of the search input border
          paper: {
            sx: {
              width: 'calc(100% - 36px)',
              ml: '18px',
            },
          },
        }}
        onChange={(event, value, reason) => {
          // Prevent selection when clicking on an option
          if (reason === 'selectOption') {
            // Don't update the input value when clicking on an option
            return;
          }
          return null;
        }}
        onHighlightChange={(_, option) => {
          setHighlightedOption(option as SearchResult);
        }}
        onInputChange={handleInputChange}
      />
      <Dialog
        aria-describedby='add-company-dialog-description'
        aria-labelledby='add-company-dialog-title'
        open={addCompanyModalOpen}
        onClose={handleCancelAddCompany}
      >
        <DialogTitle id='add-company-dialog-title'>
          {t('confirm_add_company')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='add-company-dialog-description'>
            {t('confirm_add_company_message', { name: selectedCompany?.name })}
          </DialogContentText>
          {groups && groups.length > 0 && (
            <FormControl
              fullWidth
              sx={{
                mt: 2,
                '& .MuiInputLabel-root': {
                  transform: 'translate(14px, 8px) scale(1)',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  transform: 'translate(14px, -9px) scale(0.75)',
                },
              }}
            >
              <InputLabel id='group-select-label'>{t('select_group')}</InputLabel>
              <Select
                label={t('select_group')}
                labelId='group-select-label'
                size='small'
                value={selectedGroupId}
                onChange={(e) => setSelectedGroupId(e.target.value)}
              >
                <MenuItem value=''>
                  <em>{t('none')}</em>
                </MenuItem>
                {groups.map((group) => (
                  <MenuItem
                    key={group.id}
                    value={group.id}
                  >
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAddCompany}>
            {t('no')}
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={handleConfirmAddCompany}
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompanySearchBar;
