import * as React from 'react';
import {
  Outlet,
  createFileRoute,
  Link,
  useLocation,
  useNavigate,
} from '@tanstack/react-router';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
// import PeopleIcon from '@mui/icons-material/PeopleOutline';
// import NotificationsIcon from '@mui/icons-material/NotificationsNone';
// import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
// import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import { useAuth0 } from '@auth0/auth0-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import { delay } from 'rambdax';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useApolloClient, useQuery, useMutation,
} from '@apollo/client';

import GET_GROUPS_BY_ORG_ID, {
  GroupsByOrgId,
  GroupsByOrgIdVariables,
} from '../graphql/queries/get-groups-by-org-id';
import CREATE_ACCOUNT, {
  CreateAccount,
  CreateAccountVariables,
} from '../graphql/mutations/create-account';
import SEARCH_ACCOUNTS_BY_COMPANY_NAME, {
  SearchAccountsByCompanyName,
  SearchAccountsByCompanyNameVariables,
  AccountSearchResult,
} from '../graphql/queries/search-accounts-by-company-name';
import SEARCH_COMPANIES, {
  SearchCompanies,
  SearchCompaniesVariables,
  CompanySearchResult,
} from '../graphql/queries/search-companies';
import GraphQLMutationStatusMessage from '../components/graphql-mutation-status-message/graphql-mutation-status-message';
import theme from '../theme';
import useBreakPoint from '../hooks/use-break-point';
// import LanguageSelect from '../components/language-select/language-select';
import CompanySearchBar from '../components/company-search-bar/company-search-bar';

type NavMenuItem = {
  text: string
  path: string
  icon: (props: any) => React.ReactNode
  selected?: boolean
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const DRAWER_WIDTH = 260;
const MINIMUM_SEARCH_TERM_LENGTH = 2;

export const Route = createFileRoute('/_auth')({
  component: AuthLayout,
});

/*
In this file, we use:
useQuery - for the groups query because it's a one-time fetch when the component
mounts and we want automatic re-fetching if the user's org_id changes

client.query - for the search function because:
  We need manual control over when the query runs (when the user types)
  We need to handle cancellation of previous requests when new searches are made
  We want to make the queries in parallel using Promise.all
  We don't want the queries to automatically re-run when variables change
*/
function AuthLayout () {
  const { t } = useTranslation('routes');
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoggingOut, setIsLoggingOut ] = React.useState<boolean>(false);
  const [ anchorElUser, setAnchorElUser ] = React.useState<null | Element>(null);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ internalResults, setInternalResults ] = React.useState<any[] | null>(null);
  const [ externalResults, setExternalResults ] = React.useState<any[] | null>(null);
  const [ internalTotalCount, setInternalTotalCount ] = React.useState<number>(0);
  const [ externalTotalCount, setExternalTotalCount ] = React.useState<number>(0);
  const [ hasCleanedUrl, setHasCleanedUrl ] = React.useState(false);
  const client = useApolloClient();
  const abortControllerRef = React.useRef<AbortController | null>(null);
  const latestSearchTermRef = React.useRef<string>('');
  const {
    logout,
    isLoading: auth0Loading,
    user,
    error,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const isScreenMedWidthOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation({
    select: (location) => location.pathname,
  });
  const { getBreakPointName } = useBreakPoint();
  const navigate = useNavigate();

  // Add groups query
  const { data: groupsData } = useQuery<GroupsByOrgId, GroupsByOrgIdVariables>(
    GET_GROUPS_BY_ORG_ID,
    {
      variables: {
        orgId: user?.org_id || '',
      },
      skip: !user?.org_id,
    },
  );

  const [ createAccount, { loading: isCreatingAccount, error: createAccountError } ] = useMutation<CreateAccount, CreateAccountVariables>(
    CREATE_ACCOUNT,
  );

  React.useEffect(() => {
    if (isScreenMedWidthOrSmaller) {
      setOpen(false);
    }
  }, [ isScreenMedWidthOrSmaller, location ]);

  // This is necessary in conjunction with onRedirectCallback to allow
  React.useEffect(() => {
    if (auth0Loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { returnTo: window.location.origin + window.location.pathname + window.location.hash },
      });
    };
    fn();
  }, [
    auth0Loading,
    isAuthenticated,
    loginWithRedirect,
  ]);

  // TEMP HOTFIX SOLUTION TO CLEAN UP URL
  // TODO: Use a dedicated Auth Callback URL with Auth0
  React.useEffect(() => {
    if (isAuthenticated && !auth0Loading && !hasCleanedUrl) {
      const url = new URL(window.location.href);

      if (url.searchParams.has('code') || url.searchParams.has('state')) {
        url.searchParams.delete('code');
        url.searchParams.delete('state');

        window.history.replaceState(
          {},
          document.title,
          url.pathname + (url.search ? '?' + url.searchParams.toString() : '') + url.hash,
        );
        setHasCleanedUrl(true);
      }
    }
  }, [
    isAuthenticated,
    auth0Loading,
    hasCleanedUrl,
  ]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const handleOpenUserMenu = (event: React.SyntheticEvent) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navItems: NavMenuItem[] = [
    {
      text: t('home'),
      path: '/',
      icon: (props) => <HomeIcon {...props} />,
    },
    {
      text: t('advanced_search'),
      path: '/search',
      icon: (props) => <ManageSearchIcon {...props} />,
    },
    // {
    //   text: t('notifications'),
    //   path: '/notifications',
    //   icon: (props) => <NotificationsIcon {...props} />,
    // },
    // {
    //   text: t('document_upload'),
    //   path: '/documents',
    //   icon: (props) => <ContentPasteSearchIcon {...props} />,
    // },
    // {
    //   text: t('admin_panel'),
    //   path: '/admin',
    //   icon: (props) => <SettingsIcon {...props} />,
    // },
  ];
  const settingsItems = [
    // {
    //   key: 'language_select',
    //   action: () => null,
    //   component: <LanguageSelect />,
    // },
    {
      key: 'logout',
      text: t('logout_btn'),
      icon: (isLoggingOut) ? <CircularProgress size={20} /> : <LogoutIcon />,
      action: async () => {
        setIsLoggingOut(true);
        window.sessionStorage.clear();
        // since modifications to session storage are async, we need to wait a bit
        await delay(500);
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      },
      component: null,
    },
  ];

  const baseDrawerStyles = {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: (open) ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width: 0,
    },
    [theme.breakpoints.up('md')]: {
      width: (open) ? DRAWER_WIDTH : theme.spacing(8),
    },
  };

  const handleSearch = async (searchTerm: string) => {
    // Clear results and counts immediately if search term is empty
    if (!searchTerm || searchTerm.length < MINIMUM_SEARCH_TERM_LENGTH || !user?.org_id) {
      setInternalResults(null);
      setExternalResults(null);
      setInternalTotalCount(0);
      setExternalTotalCount(0);
      return;
    }

    // Cancel any existing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    // Create new AbortController for this request
    abortControllerRef.current = new AbortController();
    // Update the latest search term
    latestSearchTermRef.current = searchTerm;

    setIsLoading(true);
    setInternalResults(null);
    setExternalResults(null);
    setInternalTotalCount(0);
    setExternalTotalCount(0);

    try {
      // Make both API calls in parallel
      const [ accountsResponse, companiesResponse ] = await Promise.all([
        client.query<SearchAccountsByCompanyName, SearchAccountsByCompanyNameVariables>({
          query: SEARCH_ACCOUNTS_BY_COMPANY_NAME,
          variables: {
            name: searchTerm,
            orgId: user.org_id,
            skip: 0,
            limit: 10,
          },
          context: { signal: abortControllerRef.current.signal },
        }),
        client.query<SearchCompanies, SearchCompaniesVariables>({
          query: SEARCH_COMPANIES,
          variables: {
            name: searchTerm,
            page: 1,
            pageSize: 10,
          },
          context: { signal: abortControllerRef.current.signal },
        }),
      ]);

      // Only update state if this is still the most recent search
      if (latestSearchTermRef.current === searchTerm && !abortControllerRef.current.signal.aborted) {
        // Transform and update internal results
        const accountResults = accountsResponse.data.searchAccountsByCompanyName.accounts.map((account: AccountSearchResult) => ({
          id: account.accountId,
          name: account.name,
          status: account.status,
          statusDescription: account.statusDescription,
          address: account.simpleAddress,
          type: account.officeType,
          externalId: account.externalId,
          source: 'internal' as const,
        }));

        // Transform external results and filter out companies that already exist internally
        const companyResults = companiesResponse.data.searchCompanies.companies
          .filter((company: CompanySearchResult) =>
            !accountResults.some((account) => account.externalId === company.companySearchId),
          )
          .map((company: CompanySearchResult) => ({
            id: company.companySearchId,
            name: company.name,
            status: company.status,
            statusDescription: company.statusDescription,
            address: company.address,
            type: company.officeType,
            source: 'external' as const,
          }));

        // Update all state at once to prevent partial updates
        setInternalResults(accountResults);
        setExternalResults(companyResults);
        setInternalTotalCount(accountsResponse.data.searchAccountsByCompanyName.totalAccounts);
        setExternalTotalCount(companiesResponse.data.searchCompanies.totalSize);
      }
    } catch (error: unknown) {
      // Only log errors that aren't from cancellation
      if (error instanceof Error && error.name !== 'AbortError') {
        console.error('Search error:', error);
      }
      // Only clear results if this is still the most recent search
      if (latestSearchTermRef.current === searchTerm && !abortControllerRef.current.signal.aborted) {
        setInternalResults(null);
        setExternalResults(null);
        setInternalTotalCount(0);
        setExternalTotalCount(0);
      }
    } finally {
      // Only update loading state if this is still the most recent search
      if (latestSearchTermRef.current === searchTerm && !abortControllerRef.current.signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  const handleViewCompany = (id: string) => {
    navigate({
      to: '/accounts/$accountId',
      params: { accountId: id },
    });
  };

  const handleAddCompany = async (id: string, groupId: string | null) => {
    if (!user?.org_id) {
      console.error('No organization ID available');
      return;
    }

    try {
      const { data } = await createAccount({
        variables: {
          orgId: user.org_id,
          groupId,
          companySearchId: id,
        },
      });

      if (data?.createAccount) {
        // Evict the search results from the cache
        client.cache.evict({ fieldName: 'searchAccountsByCompanyName' });
        client.cache.gc();

        // Navigate to the new account's page using the companySearchId
        navigate({
          to: '/accounts/$accountId',
          params: { accountId: data.createAccount },
        });
      } else {
        console.error('Failed to create account');
      }
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };

  const handleAdvancedSearchClick = (companyName: string) => {
    // Create search parameters with company name (if provided) and US country code
    const searchParams = new URLSearchParams();
    if (companyName && companyName.trim() !== '') {
      searchParams.set('companyName', companyName);
    }
    searchParams.set('country', 'US');

    navigate({
      to: `/search/${searchParams.toString()}`,
    });
  };

  if (!isAuthenticated && !auth0Loading) {
    return;
  }

  return (
    <>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Backdrop
        open={isCreatingAccount}
        sx={{
          color: '#fff',
          zIndex: 2000,
        }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <GraphQLMutationStatusMessage
        autoHide={true}
        message={isCreatingAccount ? t('create_account_pending_message') : createAccountError?.message || null}
        severity={isCreatingAccount ? 'info' : createAccountError ? 'error' : 'success'}
      />
      <Dialog
        aria-describedby='alert-dialog-description'
        aria-labelledby='alert-dialog-title'
        open={!!error}
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ErrorIcon sx={{ mr: 1 }} />
          There was an error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography>
              We were unable to authenticate you due to:
              {error?.message}
            </Typography>
            <Typography sx={{ mt: 2 }}>Click OK to log in again</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => loginWithRedirect()}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MuiAppBar
          sx={{
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create([ 'margin', 'width' ], {
              easing: theme.transitions.easing.sharp,
              duration: (open) ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
            }),
            width: (open) ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%',
            marginLeft: (open) ? `${DRAWER_WIDTH}px` : '0px',
            boxShadow: 'none',
            borderWidth: 0,
            borderStyle: 'solid',
            borderColor: 'rgba(0, 0, 0, 0.12)',
            borderBottomWidth: 'thin',
          }}
        >
          <Toolbar>
            <IconButton
              aria-label='open drawer'
              color='inherit'
              edge='start'
              sx={{
                marginRight: 0.5,
                [theme.breakpoints.up('md')]: {
                  marginRight: 2,
                },
                ...(open && { display: 'none' }),
              }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                height: 35,
                [theme.breakpoints.up('md')]: {
                  height: 45,
                },
              }}
            >
              <img
                alt={t('logo_alt_text')}
                src={(getBreakPointName() === 'xs') ? 'https://media.creditpulse.com/branding/credit-pulse-icon-transparent.png' : 'https://media.creditpulse.com/branding/credit-pulse-banner-logo-transparent.png'}
                style={{
                  verticalAlign: 'middle',
                  height: '100%',
                }}
              />
            </Box>
            <Box sx={{
              flexGrow: 1,
              mx: {
                xs: 1,
                sm: 2,
                md: 4,
              },
            }}
            >
              <CompanySearchBar
                externalSearchResults={externalResults}
                externalTotalCount={externalTotalCount}
                groups={groupsData?.getGroupsByOrgId?.groups ?? null}
                internalSearchResults={internalResults}
                internalTotalCount={internalTotalCount}
                isLoading={isLoading}
                onAddCompany={handleAddCompany}
                onAdvancedSearchClick={handleAdvancedSearchClick}
                onCloseMenu={handleCloseUserMenu}
                onSearch={handleSearch}
                onViewCompany={handleViewCompany}
              />
            </Box>
            <Box sx={{ flexShrink: 1 }}>
              <Tooltip title={t('avatar_tooltip')}>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={handleOpenUserMenu}
                >
                  <Avatar
                    alt={user?.name}
                    src={user?.picture}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                keepMounted
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id='menu-appbar'
                open={Boolean(anchorElUser)}
                sx={{ mt: '45px' }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleCloseUserMenu}
              >
                {settingsItems.map((setting) => (
                  <MenuItem
                    key={setting.key}
                    onClick={setting.action}
                  >
                    {setting.component}
                    {setting.icon}
                    <Typography sx={{
                      paddingLeft: 1,
                      textAlign: 'center',
                    }}
                    >
                      {setting.text}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </MuiAppBar>
        <MuiDrawer
          sx={{
            ...baseDrawerStyles,
            '& .MuiDrawer-paper': {
              ...baseDrawerStyles,
              [theme.breakpoints.down('md')]: {
                width: (open) ? DRAWER_WIDTH : 0,
              },
            },
          }}
          variant='permanent'
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {navItems.map(({
              text,
              path,
              icon,
            }) => (
              <Link
                key={text}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                to={path}
              >
                {({ isActive }) => (
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={isActive}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {icon({ color: (isActive) ? 'primary' : '' })}
                      </ListItemIcon>
                      <ListItemText
                        disableTypography={true}
                        primary={text}
                        sx={{
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </Link>
            ))}
          </List>
        </MuiDrawer>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            m: 3,
          }}
        >
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
