import React, { useState, useEffect } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface GraphqlMutationStatusMessageProps {
  message?: string | null | undefined;
  autoHide?: boolean;
  severity: 'error' | 'warning' | 'info' | 'success';
};

const AUTO_HIDE_DURATION = 6000;
const GraphqlMutationStatusMessage: React.FC<GraphqlMutationStatusMessageProps> = ({
  message = null,
  autoHide = true,
  severity,
}) => {
  const [ isAcknowledged, setIsAcknowledged ] = useState<boolean>(false);
  // Anytime message changes, reset the acknowledged state
  useEffect(() => {
    setIsAcknowledged(false);
  }, [ message ]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason !== 'clickaway') {
      setIsAcknowledged(true);
    }
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={(autoHide) ? AUTO_HIDE_DURATION : null}
      open={!!message && !isAcknowledged}
      onClose={handleClose}
    >
      <Alert
        severity={severity}
        sx={{ width: '100%' }}
        variant='filled'
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GraphqlMutationStatusMessage;
