type DebouncedFunction = {
  (...args: any[]): void;
  cancel: () => void;
}

const debounce = (func: Function, wait: number): DebouncedFunction => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let pendingArgs: any[] | null = null;

  const debounced = function (...args: any[]): void {
    // Store the latest arguments
    pendingArgs = args;

    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    timeoutId = setTimeout(() => {
      // Only execute if we have pending arguments
      if (pendingArgs !== null) {
        func(...pendingArgs);
      }
      // Reset state
      pendingArgs = null;
      timeoutId = null;
    }, wait);
  };

  debounced.cancel = function (): void {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = null;
    pendingArgs = null;
  };

  return debounced;
};

export default debounce;
