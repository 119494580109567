import { gql } from '@apollo/client';

export type CompanySearchResult = {
  companySearchId: string;
  name: string;
  websites: string;
  address: string;
  status: string;
  statusDescription: string;
  officeType: string;
}

export type SearchCompanies = {
  searchCompanies: {
    totalSize: number;
    companies: CompanySearchResult[];
  };
}

export type SearchCompaniesVariables = {
  name: string;
  website?: string;
  street?: string;
  city?: string;
  province?: string;
  countries?: string[];
  postCode?: string;
  page?: number;
  pageSize?: number;
}

const SEARCH_COMPANIES = gql`
  query searchCompanies(
    $name: String
    $website: String
    $street: String
    $city: String
    $province: String
    $countries: [String]
    $postCode: String
    $page: Int
    $pageSize: Int
  ) {
    searchCompanies(
      name: $name
      website: $website
      street: $street
      city: $city
      province: $province
      countries: $countries
      postCode: $postCode
      page: $page
      pageSize: $pageSize
    ) {
      totalSize
      companies {
        companySearchId
        name
        websites
        address
        status
        statusDescription
        officeType
      }
    }
  }
`;

export default SEARCH_COMPANIES;
