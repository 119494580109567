import { gql } from '@apollo/client';

export type Group = {
  id: string;
  name: string;
}

export type GroupsByOrgId = {
  getGroupsByOrgId: {
    groups: Group[];
    total: number;
  };
}

export type GroupsByOrgIdVariables = {
  orgId: string;
  skip?: number;
  limit?: number;
}

const GET_GROUPS_BY_ORG_ID = gql`
query getGroupsByOrgId($orgId: ID!, $skip: Int, $limit: Int) {
  getGroupsByOrgId(orgId: $orgId, skip: $skip, limit: $limit) {
    groups {
      id
      name
    }
  }
}
`;

export default GET_GROUPS_BY_ORG_ID;
